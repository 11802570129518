<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="form.controls.title.value || (titleEditable ? 'tabs' : 'Tabs')"
    [titleEnabled]="titleEnabled"
    [titleEditable]="titleEditable"
    [titleCleanValue]="titleCleanValue"
    [backLabel]="'All components'"
    [deleteEnabled]="deleteEnabled"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (deleteClick)="delete()"
    (titleChanged)="onTitleChanged($event)"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <app-sidebar-section *ngIf="form.controls.items.value">
          <div class="sidebar__list">
            <div cdkDropList [cdkDropListLockAxis]="'y'" class="sidebar-list" (cdkDropListDropped)="dragDrop($event)">
              <div
                *ngFor="let control of form.controls.items.controls; let i = index"
                [formGroup]="control"
                cdkDrag
                class="sidebar-list__item"
              >
                <app-customize-bar-tabs-edit-item
                  [control]="control"
                  [context]="context"
                  [contextElement]="contextElement"
                  [collapseContext]="collapseContext"
                  [openedInitial]="control === createdItem"
                  [removeEnabled]="form.controls.items.controls.length > 1"
                  [analyticsSource]="element.analyticsGenericName + '_item'"
                  (remove)="removeItem(control, i)"
                ></app-customize-bar-tabs-edit-item>
              </div>

              <div *ngIf="!form.controls.items.controls.length" class="sidebar-list__item">
                <div
                  class="sidebar-list-item sidebar-list-item_clickable sidebar-list-item_disabled"
                  (click)="addItem()"
                >
                  <div class="sidebar-list-item__drag"></div>
                  <div class="sidebar-list-item__main">
                    <div class="sidebar-list-item__title">Tab</div>
                    <div class="sidebar-list-item__description">Not specified</div>
                  </div>
                </div>
              </div>

              <div class="sidebar-list__buttons">
                <a
                  href="javascript:void(0)"
                  class="button button_orange-transparent button_small sidebar-list__buttons-item"
                  (click)="addItem()"
                >
                  <span class="icon icon-plus button__icon button__icon_left"></span>
                  <span class="button__label">Add Tab</span>
                </a>

                <app-field-errors
                  *ngIf="!form.controls.items?.length"
                  [form]="form"
                  [fieldName]="'items'"
                ></app-field-errors>
              </div>
            </div>
          </div>
        </app-sidebar-section>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'load tabs only after open'" [layoutLeft]="true">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'load_on_show',
                  field: 'BooleanField',
                  params: { style: booleanFieldStyle.Toggle, orange: true }
                })
              "
            ></app-auto-field>

            <ng-container description>Use this option to improve performance</ng-container>
          </app-sidebar-field>
        </div>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <div class="sidebar__element">
          <app-sidebar-field>
            <div class="sidebar-icon-buttons sidebar-icon-buttons_border">
              <div
                class="sidebar-icon-button"
                [class.sidebar-icon-button_active]="form.controls.orientation.value == tabsOrientations.Horizontal"
                (click)="form.controls.orientation.setValue(tabsOrientations.Horizontal)"
              >
                <div class="sidebar-icon-button__label">Horizontal</div>
              </div>
              <div
                class="sidebar-icon-button"
                [class.sidebar-icon-button_active]="form.controls.orientation.value == tabsOrientations.Vertical"
                (click)="form.controls.orientation.setValue(tabsOrientations.Vertical)"
              >
                <div class="sidebar-icon-button__label">Vertical</div>
              </div>
            </div>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'Appearance'">
            <div class="sidebar-icon-buttons sidebar-icon-buttons_border">
              <div
                class="sidebar-icon-button"
                [class.sidebar-icon-button_active]="form.controls.appearance.value == tabsAppearance.Link"
                (click)="form.controls.appearance.setValue(tabsAppearance.Link)"
              >
                <div class="sidebar-icon-button__label">Links</div>
              </div>
              <div
                class="sidebar-icon-button"
                [class.sidebar-icon-button_active]="form.controls.appearance.value == tabsAppearance.Button"
                (click)="form.controls.appearance.setValue(tabsAppearance.Button)"
              >
                <div class="sidebar-icon-button__label">Buttons</div>
              </div>
            </div>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'style'">
            <div class="sidebar-image-toggle sidebar-image-toggle_cols_3">
              <div *ngFor="let item of form.styleOptions" class="sidebar-image-toggle__col">
                <div
                  class="sidebar-image-toggle__item"
                  [class.sidebar-image-toggle__item_active]="item.value === form.controls.style.value"
                  (click)="form.controls.style.patchValue(item.value)"
                >
                  <div
                    class="sidebar-image-toggle__item-inner"
                    [style.padding-top.%]="(49 / 70) * 100"
                    [style.background-image]="'/assets/images/tabs/' + item.image + '.svg' | appDeployCssUrl"
                  ></div>
                </div>
              </div>
            </div>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field
            [label]="'Conditional Visibility'"
            [documentation]="'conditional-visibility'"
            [documentationLabel]="'See how it works'"
            [documentationSource]="element.analyticsGenericName + '_visible'"
          >
            <app-input-edit
              [itemForm]="form.controls.visible_input"
              [context]="context"
              [staticValueDisabled]="true"
              [placeholder]="'Value'"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_visible'"
            ></app-input-edit>

            <ng-container description>
              Example: elements.field.value >= 10
            </ng-container>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'load content when hidden'" [layoutLeft]="true">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'load_invisible',
                  field: 'BooleanField',
                  params: { style: booleanFieldStyle.Toggle, orange: true }
                })
              "
            ></app-auto-field>

            <ng-container description>This can decrease performance</ng-container>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'Spacing'">
            <app-margin-control [control]="form.controls.margin"></app-margin-control>
          </app-sidebar-field>
        </div>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>

  <!--  <app-sidebar-submit-->
  <!--    [saveDisabled]="form.form.invalid || form.form.disabled"-->
  <!--    [errors]="form.form | appFormErrors"-->
  <!--    (cancelClick)="cancel()"-->
  <!--    (deleteClick)="delete()"-->
  <!--  >-->
  <!--  </app-sidebar-submit>-->
</form>

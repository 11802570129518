import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of, timer } from 'rxjs';
import { debounce, map, skip, switchMap } from 'rxjs/operators';

import { ActionDescription, ActionItem, ActionType } from '@modules/actions';
import { AllProjectSettings, HomeType, ProjectSettingsStore } from '@modules/all-project-settings';
import { CustomizeService, ViewSettings, ViewSettingsStore } from '@modules/customize';
import { GlobalContext } from '@modules/customize-utils';
import { FieldOutput, FieldType, Input as FieldInput, InputValueType } from '@modules/fields';
import { MenuGroupItem } from '@modules/menu';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  HomeTriggerOutput,
  ProjectSettingsName,
  ProjectSettingsService
} from '@modules/projects';
import {
  ActionWorkflowStep,
  ConditionWorkflowStep,
  ConditionWorkflowStepItem,
  ConditionWorkflowStepType,
  Workflow
} from '@modules/workflow';
import { isSet } from '@shared';

import { WorkflowEditController } from '../../services/workflow-edit-controller/workflow-edit.controller';

@Component({
  selector: 'app-customize-home',
  templateUrl: './customize-home.component.html',
  providers: [GlobalContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeHomeComponent implements OnInit, OnDestroy {
  @Input() menuItems: MenuGroupItem[] = [];
  @Input() disabled = false;
  @Input() dark = false;
  @Input() dropdownLeft = false;
  @Input() analyticsSource: string;
  @Output() startInteracting = new EventEmitter<void>();
  @Output() finishInteracting = new EventEmitter<void>();

  loading = false;
  projectSettings: AllProjectSettings;
  displayValue: string;
  menuItemValue: MenuGroupItem;
  homeTypes = HomeType;
  typeOpened$ = new BehaviorSubject<boolean>(false);
  pagesOpened$ = new BehaviorSubject<boolean>(false);
  workflowOpened$ = new BehaviorSubject<boolean>(false);
  saving$ = new BehaviorSubject<boolean>(false);
  typeDropdownPositions: ConnectedPosition[] = [];
  pagesDropdownPositions: ConnectedPosition[] = [];

  constructor(
    private context: GlobalContext,
    public customizeService: CustomizeService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectSettingsService: ProjectSettingsService,
    private projectSettingsStore: ProjectSettingsStore,
    private viewSettingsStore: ViewSettingsStore,
    private workflowEditController: WorkflowEditController,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.typeDropdownPositions = this.getTypeDropdownPositions();
    this.pagesDropdownPositions = this.getPagesDropdownPositions();

    this.loading = true;
    this.cd.markForCheck();

    combineLatest(
      this.projectSettingsStore.getAllSettings$(),
      this.projectSettingsStore.getAllSettings$().pipe(
        switchMap(projectSettings => {
          if (projectSettings && projectSettings.homeType == HomeType.Page && isSet(projectSettings.homePageUid)) {
            return this.viewSettingsStore.getDetailByUid(projectSettings.homePageUid);
          } else {
            return of(undefined);
          }
        })
      )
    )
      .pipe(untilDestroyed(this))
      .subscribe(([projectSettings, homePage]) => {
        this.projectSettings = projectSettings;

        if (projectSettings && projectSettings.homeType == HomeType.FirstMenuItem) {
          this.displayValue = 'First menu page';
          this.menuItemValue = this.menuItems[0];
        } else if (projectSettings && projectSettings.homeType == HomeType.Page) {
          this.displayValue = homePage ? homePage.name : 'Unknown page';
          this.menuItemValue = homePage
            ? this.menuItems.find(item => isSet(item.pageUid) && item.pageUid == homePage.uid)
            : undefined;
        } else if (projectSettings && projectSettings.homeType == HomeType.Workflow) {
          this.displayValue = 'Using Workflow';
          this.menuItemValue = undefined;
        }

        this.loading = false;
        this.cd.markForCheck();
      });

    combineLatest(
      this.typeOpened$.pipe(debounce(opened => timer(opened ? 0 : 200))),
      this.pagesOpened$.pipe(debounce(opened => timer(opened ? 0 : 200))),
      this.workflowOpened$.pipe(debounce(opened => timer(opened ? 0 : 600))),
      this.saving$
    )
      .pipe(
        map(([typeOpened, pagesOpened, workflowOpened, saving]) => {
          return typeOpened || pagesOpened || workflowOpened || saving;
        }),
        skip(1),
        untilDestroyed(this)
      )
      .subscribe(interacting => {
        if (interacting) {
          this.startInteracting.emit();
        } else {
          this.finishInteracting.emit();
        }
      });
  }

  ngOnDestroy(): void {}

  setHomeFirstMenuItem() {
    this.updateHome({ homeType: HomeType.FirstMenuItem });
  }

  setHomePage(page: ViewSettings) {
    this.updateHome({ homeType: HomeType.Page, homePageUid: page.uid });
  }

  editHomeWorkflow() {
    let workflow: Workflow;

    if (this.projectSettings.homeWorkflow) {
      workflow = cloneDeep(this.projectSettings.homeWorkflow);
    } else {
      workflow = new Workflow();
      workflow.generateUid();

      const conditionStep = new ConditionWorkflowStep();
      const getLinkAction = (triggerOutput?: HomeTriggerOutput) => {
        const actionStep = new ActionWorkflowStep();

        actionStep.generateUid();

        if (triggerOutput == HomeTriggerOutput.AcceptInvite) {
          actionStep.name = `Navigate after invite accepted`;
        } else if (triggerOutput == HomeTriggerOutput.Register) {
          actionStep.name = `Navigate after sign up`;
        } else if (triggerOutput == HomeTriggerOutput.Login) {
          actionStep.name = `Navigate after login`;
        } else if (!isSet(triggerOutput)) {
          actionStep.name = `Default navigate`;
        }

        actionStep.action = new ActionItem();
        actionStep.action.actionDescription = new ActionDescription();
        actionStep.action.actionDescription.type = ActionType.Link;

        return actionStep;
      };

      conditionStep.generateUid();
      conditionStep.name = 'Condition';
      conditionStep.conditionType = ConditionWorkflowStepType.Switch;
      conditionStep.items = [HomeTriggerOutput.Register, HomeTriggerOutput.AcceptInvite].map((triggerOutput, i) => {
        const stepItem = new ConditionWorkflowStepItem();

        stepItem.generateUid();

        if (triggerOutput == HomeTriggerOutput.AcceptInvite) {
          stepItem.label = `After invite accepted`;
        } else if (triggerOutput == HomeTriggerOutput.Register) {
          stepItem.label = `After sign up`;
        }

        stepItem.condition = new FieldInput();
        stepItem.condition.path = ['value'];
        stepItem.condition.valueType = InputValueType.Context;
        stepItem.condition.contextValue = ['workflow', triggerOutput];

        const actionStep = getLinkAction(triggerOutput);

        stepItem.steps = [actionStep];

        return stepItem;
      });

      const elseActionStep = getLinkAction(HomeTriggerOutput.Login);

      conditionStep.elseSteps = [elseActionStep];
      conditionStep.elseLabel = 'After login';

      workflow.steps = [conditionStep];
    }

    const outputs = [
      {
        name: HomeTriggerOutput.Register,
        verboseName: 'After sign up',
        field: FieldType.Boolean,
        description: 'Session after user has signed up',
        icon: 'signup'
      },
      {
        name: HomeTriggerOutput.AcceptInvite,
        verboseName: 'After invite accepted',
        field: FieldType.Boolean,
        description: 'Session after user has accepted invite',
        icon: 'email'
      },
      {
        name: HomeTriggerOutput.Login,
        verboseName: 'After login',
        field: FieldType.Boolean,
        description: 'Session after user has logged in',
        icon: 'signin'
      }
    ].map(item => {
      const output = new FieldOutput();

      output.name = item.name;
      output.verboseName = item.verboseName;
      output.field = item.field;
      output.icon = item.icon;
      output.updateFieldDescription();

      return output;
    });

    this.workflowOpened$.next(true);

    this.workflowEditController
      .open({
        create: !this.projectSettings.homeWorkflow,
        workflow: workflow,
        workflowRun: workflow.testRun,
        workflowEditable: true,
        parameters: [],
        triggerOutputs: outputs,
        triggerLabel: 'When app opens',
        triggerIcon: 'home',
        customizeTrigger: false,
        context: this.context,
        actionTypesEnabled: [
          ActionType.Query,
          ActionType.Link,
          ActionType.ExternalLink,
          ActionType.ShowNotification,
          ActionType.SetProperty,
          ActionType.RunJavaScript,
          ActionType.CopyToClipboard
        ],
        analyticsSource: this.analyticsSource
      })
      // .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.workflowOpened$.next(false);

          if (result.cancelled) {
            return;
          }

          result.workflow.testRun = result.workflowRun;

          this.setHomeWorkflow(result.workflow);
        },
        () => {
          this.workflowOpened$.next(false);
        }
      );
  }

  setHomeWorkflow(workflow: Workflow) {
    this.updateHome({ homeType: HomeType.Workflow, homeWorkflow: workflow });
  }

  updateHome(settings: Partial<AllProjectSettings>) {
    const updateProjectSettings = new AllProjectSettings(settings).serialize([ProjectSettingsName.Home]);

    this.saving$.next(true);

    this.projectSettingsService
      .createBulk(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        updateProjectSettings
      )
      .pipe(
        switchMap(() => this.projectSettingsStore.getFirst(true))
        // untilDestroyed(this)
      )
      .subscribe(
        () => {
          this.saving$.next(false);
        },
        () => {
          this.saving$.next(false);
        }
      );
  }

  getTypeDropdownPositions(): ConnectedPosition[] {
    return this.dropdownLeft
      ? [
          {
            panelClass: ['overlay_position_bottom-right'],
            originX: 'end',
            overlayX: 'end',
            originY: 'bottom',
            overlayY: 'top',
            offsetY: 8
          },
          {
            panelClass: ['overlay_position_top-right'],
            originX: 'end',
            overlayX: 'end',
            originY: 'top',
            overlayY: 'bottom',
            offsetY: -8
          }
        ]
      : [
          {
            panelClass: ['overlay_position_bottom-left'],
            originX: 'start',
            overlayX: 'start',
            originY: 'bottom',
            overlayY: 'top',
            offsetY: 8
          },
          {
            panelClass: ['overlay_position_top-left'],
            originX: 'start',
            overlayX: 'start',
            originY: 'top',
            overlayY: 'bottom',
            offsetY: -8
          }
        ];
  }

  getPagesDropdownPositions(): ConnectedPosition[] {
    return this.dropdownLeft
      ? [
          {
            panelClass: ['overlay_position_left-top'],
            originX: 'start',
            overlayX: 'end',
            originY: 'top',
            overlayY: 'top',
            offsetX: 8,
            offsetY: -8
          },
          {
            panelClass: ['overlay_position_left-bottom'],
            originX: 'start',
            overlayX: 'end',
            originY: 'bottom',
            overlayY: 'bottom',
            offsetX: 8,
            offsetY: 8
          },
          {
            panelClass: ['overlay_position_left-center'],
            originX: 'start',
            overlayX: 'end',
            originY: 'center',
            overlayY: 'center',
            offsetX: 8,
            offsetY: 0
          }
        ]
      : [
          {
            panelClass: ['overlay_position_right-top'],
            originX: 'end',
            overlayX: 'start',
            originY: 'top',
            overlayY: 'top',
            offsetX: -8,
            offsetY: -8
          },
          {
            panelClass: ['overlay_position_right-bottom'],
            originX: 'end',
            overlayX: 'start',
            originY: 'bottom',
            overlayY: 'bottom',
            offsetX: -8,
            offsetY: 8
          },
          {
            panelClass: ['overlay_position_right-center'],
            originX: 'end',
            overlayX: 'start',
            originY: 'center',
            overlayY: 'center',
            offsetX: -8,
            offsetY: 0
          }
        ];
  }
}

<ng-container *ngIf="!readonly" [formGroup]="form">
  <div *ngIf="label" class="field" [class.field_manual-margin]="manualMargin">
    <div class="checkbox-control" [class.checkbox-control_theme]="field.params['theme']">
      <app-checkbox
        *ngIf="style == styles.Checkbox"
        class="checkbox-control__checkbox"
        [control]="control"
        [accentColor]="accentColor"
        [theme]="field.params['theme']"
      ></app-checkbox>

      <app-toggle
        *ngIf="style == styles.Toggle"
        [selected]="currentValue"
        [orange]="field.params['orange']"
        [disabled]="control?.disabled"
        (click)="toggle()"
      ></app-toggle>

      <label
        class="checkbox-control__label checkbox-label handle"
        [appTextStyle]="labelStyle"
        [appTextStyleGlobal]="labelStyleGlobal"
        (click)="toggle()"
      >
        {{ field.label }}
      </label>

      <span
        *ngIf="tooltip | appIsSet"
        class="checkbox-control__help help-icon icon-help"
        [appTip]="tooltip"
        [appTipOptions]="{ side: 'top' }"
      ></span>
    </div>

    <app-field-errors *ngIf="errors" [form]="form" [fieldName]="field.name"></app-field-errors>
  </div>

  <ng-container *ngIf="!label">
    <app-checkbox *ngIf="style == styles.Checkbox" [control]="control" [accentColor]="accentColor"></app-checkbox>

    <app-toggle
      *ngIf="style == styles.Toggle"
      [selected]="currentValue"
      [orange]="field.params['orange']"
      [disabled]="control?.disabled"
      (click)="toggle()"
    ></app-toggle>

    <app-field-errors *ngIf="errors" [form]="form" [fieldName]="field.name"></app-field-errors>
  </ng-container>
</ng-container>

<ng-container *ngIf="readonly">
  <div *ngIf="label" class="field" [class.field_manual-margin]="manualMargin">
    <label class="field__label" [for]="field.name | appUniqueId: idToken">
      <span class="field__label-text" [appTextStyle]="labelStyle" [appTextStyleGlobal]="labelStyleGlobal">
        {{ field.label }}
      </span>

      <span
        *ngIf="tooltip | appIsSet"
        class="field__label-help help-icon icon-help"
        [appTip]="tooltip"
        [appTipOptions]="{ side: 'top' }"
      ></span>
    </label>

    <span
      *ngIf="currentValue | appIsSet"
      class="checkmark"
      [class.checkmark_on]="currentValue"
      [class.checkmark_off]="!currentValue"
    >
      <span class="checkmark__icon" [class.icon-check_2]="currentValue" [class.icon-close]="!currentValue"></span>
    </span>

    <span *ngIf="!(currentValue | appIsSet)">
      ---
    </span>
  </div>

  <ng-container *ngIf="!label">
    <span
      *ngIf="currentValue | appIsSet"
      class="checkmark"
      [class.checkmark_on]="currentValue"
      [class.checkmark_off]="!currentValue"
    >
      <span class="checkmark__icon" [class.icon-check_2]="currentValue" [class.icon-close]="!currentValue"></span>
    </span>

    <span *ngIf="!(currentValue | appIsSet)">
      ---
    </span>
  </ng-container>
</ng-container>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import groupBy from 'lodash/groupBy';
import range from 'lodash/range';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';

import { ActionService } from '@modules/action-queries';
import { TintStyle } from '@modules/actions';
import { AggregateFunc, DatasetGroupLookup } from '@modules/charts';
import { CalendarSettings } from '@modules/customize';
import { DataSourceType } from '@modules/data-sources';
import { gteFieldLookup, lteFieldLookup } from '@modules/field-lookups';
import { applyParamInputs } from '@modules/fields';
import { FilterItem2 } from '@modules/filters';
import { ModelService } from '@modules/model-queries';
import { DATE_PARAM, TYPE_PARAM } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ChartWidgetQuery } from '@modules/queries';
import { getQueryOptionsToParams, paramsToGetQueryOptions } from '@modules/resources';

import { CalendarType } from '../../data/calendar-type';
import { DayOfWeek, WeeksDay } from '../calendar-month/calendar-month.component';
import { CalendarState } from '../calendar/calendar-state';

export interface Month {
  date: moment.Moment;
  weekDays: DayOfWeek[];
  weeks: WeeksDay[][];
}

@Component({
  selector: 'app-calendar-year',
  templateUrl: './calendar-year.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarYearComponent implements OnInit, OnDestroy, OnChanges {
  @Input() date: moment.Moment;
  @Input() params: Object;
  @Input() settings: CalendarSettings;
  @Input() listState: CalendarState;
  @Output() queryParamsChanged = new EventEmitter<Object>();

  loading = false;
  months: Month[] = [];
  items = {};
  itemsGroupBy = 'DD.MM.YYYY';
  fetchSubscription: Subscription;
  tintStyles = TintStyle;

  constructor(
    private modelService: ModelService,
    private actionService: ActionService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateMonths();
    this.updateItems(this.listState);
  }

  get now() {
    return moment();
  }

  updateMonths() {
    this.months = range(0, 12).map(month => {
      const monthStart = this.date.clone().startOf('year').add(month, 'months');
      const firstDay = monthStart.clone().startOf('isoWeek');
      const lastDay = monthStart.clone().endOf('month').endOf('isoWeek');
      const weeks = Math.ceil(lastDay.diff(firstDay, 'weeks', true));

      return {
        date: monthStart,
        weekDays: range(0, 7).map(day => {
          const date = firstDay.clone().add(day, 'days');

          return {
            date: date,
            weekend: [6, 0].includes(date.day()),
            today: date.isSame(this.now, 'day') && date.isSame(this.now, 'month') && date.isSame(this.now, 'year')
          };
        }),
        weeks: range(0, weeks).map(week => {
          return range(0, 7).map(day => {
            const date = firstDay.clone().add(week, 'weeks').add(day, 'days');

            return {
              date: date,
              today: date.isSame(this.now, 'day') && date.isSame(this.now, 'month') && date.isSame(this.now, 'year'),
              currentMonth: date.isSame(monthStart, 'month') && date.isSame(monthStart, 'year'),
              weekend: [6, 0].includes(date.day()),
              future: date.isAfter(this.now)
            };
          });
        })
      };
    });

    this.cd.markForCheck();
  }

  updateItems(state: CalendarState) {
    if (this.fetchSubscription) {
      this.fetchSubscription.unsubscribe();
      this.fetchSubscription = undefined;
    }

    this.items = {};
    this.cd.markForCheck();

    if (!this.settings.dataSource || !this.settings.dataSource.query) {
      return;
    }

    const resource =
      this.settings.dataSource && this.settings.dataSource.type == DataSourceType.Query
        ? this.currentEnvironmentStore.resources.find(item => item.uniqueName == this.settings.dataSource.queryResource)
        : undefined;

    const modelQuery = this.settings.dataSource.query;
    const query = new ChartWidgetQuery();

    query.queryType = modelQuery.queryType;
    query.simpleQuery = modelQuery.simpleQuery;
    query.httpQuery = modelQuery.httpQuery;
    query.sqlQuery = modelQuery.sqlQuery;

    const queryOptions = paramsToGetQueryOptions(state.dataSourceParams);
    const firstDay = this.date.clone().startOf('year');
    const lastDay = this.date.clone().endOf('year');

    queryOptions.filters = [
      ...queryOptions.filters,
      ...state.filters,
      new FilterItem2({
        field: [state.dateField],
        lookup: gteFieldLookup,
        value: firstDay.toISOString()
      }),
      new FilterItem2({
        field: [state.dateField],
        lookup: lteFieldLookup,
        value: lastDay.toISOString()
      })
    ];

    const params = getQueryOptionsToParams(queryOptions);

    this.loading = true;
    this.cd.markForCheck();

    this.fetchSubscription = this.modelService
      .groupQuery(
        this.currentProjectStore.instance,
        this.currentEnvironmentStore.instance,
        resource,
        query,
        [{ xColumn: this.settings.dateField, xLookup: DatasetGroupLookup.DateDay }],
        AggregateFunc.Count,
        undefined,
        state.dataSource.queryParameters,
        this.applyParams(params)
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        items => {
          this.items = items ? groupBy(items, item => moment(item['group']).format(this.itemsGroupBy)) : {};
          this.loading = false;
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  monthParams(date) {
    const params = {};
    params[TYPE_PARAM] = CalendarType.Month;
    params[DATE_PARAM] = date.toISOString();
    return this.applyParams(params);
  }

  weekParams(date) {
    const params = {};
    params[TYPE_PARAM] = CalendarType.Week;
    params[DATE_PARAM] = date.toISOString();
    return this.applyParams(params);
  }

  applyParams(params: Object = {}) {
    //   return {
    //     ...applyParamInputs(this.params, this.settings.inputs, {
    //       context: this.contextElement.context,
    //       contextElement: this.contextElement,
    //       parameters: this.settings.parameters
    //     }),
    //     ...params
    //   };
    return params;
  }

  setParams(params: Object) {
    this.queryParamsChanged.emit({
      ...this.params,
      ...params
    });
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../table-editable-cell/table-editable-cell.component.ngfactory";
import * as i2 from "../table-editable-cell/table-editable-cell.component";
import * as i3 from "../table-item-column/table-item-column.component.ngfactory";
import * as i4 from "../table-item-column/table-item-column.component";
import * as i5 from "../../../custom-views/stores/custom-views.store";
import * as i6 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i7 from "@angular/common";
import * as i8 from "../table-item-action/table-item-action.component.ngfactory";
import * as i9 from "../table-item-action/table-item-action.component";
import * as i10 from "../../../customize/services/customize/customize.service";
import * as i11 from "../../../action-queries/services/action/action.service";
import * as i12 from "../../../../common/notifications/services/notification/notification.service";
import * as i13 from "./table-item.component";
import * as i14 from "@angular/router";
import * as i15 from "../../../resources/services/resource-controller/resource-controller.service";
import * as i16 from "../../../../core/services/local-storage/local.storage";
var styles_TableItemComponent = [];
var RenderType_TableItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TableItemComponent, data: {} });
export { RenderType_TableItemComponent as RenderType_TableItemComponent };
function View_TableItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "table-checkbox table__checkbox"]], [[2, "table-checkbox_checked", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checked; _ck(_v, 0, 0, currVal_0); }); }
function View_TableItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0]], null, 1, "td", [["app-table-editable-cell", ""]], [[2, "table__column_compact", null], [2, "table__column_no-trunc", null], [4, "min-width", "px"], [4, "max-width", "px"], [1, "data-field", 0], [2, "table__column", null], [2, "table__column_clickable", null], [2, "table__column_selected", null]], [[null, "changeValue"], [null, "selectCell"], [null, "click"], [null, "dblclick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("dblclick" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onDblClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("changeValue" === en)) {
        var pd_2 = (_co.changeColumnValue.emit({ name: _v.parent.context.$implicit.column.name, value: $event }) !== false);
        ad = (pd_2 && ad);
    } if (("selectCell" === en)) {
        var pd_3 = (_co.onClick($event.event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_TableEditableCellComponent_0, i1.RenderType_TableEditableCellComponent)), i0.ɵdid(1, 770048, [["column_element", 4]], 0, i2.TableEditableCellComponent, [i0.ElementRef, i0.ChangeDetectorRef], { fieldName: [0, "fieldName"], fieldType: [1, "fieldType"], fieldParams: [2, "fieldParams"], value: [3, "value"], valueStr: [4, "valueStr"], selectedRow: [5, "selectedRow"], selectedCell: [6, "selectedCell"], editingEnabled: [7, "editingEnabled"], disableSelectOnEdit: [8, "disableSelectOnEdit"], context: [9, "context"], contextElement: [10, "contextElement"] }, { selectCell: "selectCell", changeValue: "changeValue" })], function (_ck, _v) { var _co = _v.component; var currVal_8 = _v.parent.context.$implicit.column.name; var currVal_9 = _v.parent.context.$implicit.column.field; var currVal_10 = _v.parent.context.$implicit.column.params; var currVal_11 = _co.row.model.getAttribute(_v.parent.context.$implicit.column.name); var currVal_12 = _v.parent.context.$implicit.listItem.str; var currVal_13 = _co.selected; var currVal_14 = undefined; var currVal_15 = true; var currVal_16 = true; var currVal_17 = _co.context; var currVal_18 = _co.contextElement; _ck(_v, 1, 1, [currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18]); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.context.$implicit.column.field == _co.fieldTypes.Image); var currVal_1 = ((_v.parent.context.$implicit.column.field == _co.fieldTypes.Text) && _v.parent.context.$implicit.column.params["multiline"]); var currVal_2 = _v.parent.context.$implicit.width; var currVal_3 = _v.parent.context.$implicit.width; var currVal_4 = _v.parent.context.$implicit.column.name; var currVal_5 = i0.ɵnov(_v, 1).cls; var currVal_6 = i0.ɵnov(_v, 1).clickable; var currVal_7 = i0.ɵnov(_v, 1).selected; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_TableItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0]], null, 1, "td", [["app-table-item-column", ""], ["class", "table__column"]], [[2, "table__column_clickable", null], [2, "table__column_compact", null], [2, "table__column_no-trunc", null], [4, "min-width", "px"], [4, "max-width", "px"], [1, "data-field", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_TableItemColumnComponent_0, i3.RenderType_TableItemColumnComponent)), i0.ɵdid(1, 770048, [["column_element", 4]], 0, i4.TableItemColumnComponent, [i5.CustomViewsStore, i6.ActionControllerService, i0.ChangeDetectorRef], { row: [0, "row"], column: [1, "column"], model: [2, "model"], modelDescription: [3, "modelDescription"], settings: [4, "settings"], selected: [5, "selected"], context: [6, "context"], contextElement: [7, "contextElement"], theme: [8, "theme"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.row; var currVal_7 = _v.parent.context.$implicit; var currVal_8 = _co.row.model; var currVal_9 = _co.modelDescription; var currVal_10 = _co.settings; var currVal_11 = _co.selected; var currVal_12 = _co.context; var currVal_13 = _co.contextElement; var currVal_14 = _co.theme; _ck(_v, 1, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = (_v.parent.context.$implicit.column.field == _co.fieldTypes.Image); var currVal_2 = ((_v.parent.context.$implicit.column.field == _co.fieldTypes.Text) && _v.parent.context.$implicit.column.params["multiline"]); var currVal_3 = _v.parent.context.$implicit.width; var currVal_4 = _v.parent.context.$implicit.width; var currVal_5 = _v.parent.context.$implicit.column.name; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
function View_TableItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableItemComponent_3)), i0.ɵdid(2, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableItemComponent_4)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.settings.editingEnabled && _co.isEditableColumn(_v.context.$implicit)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.settings.editingEnabled || !_co.isEditableColumn(_v.context.$implicit)); _ck(_v, 4, 0, currVal_1); }, null); }
function View_TableItemComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-table-item-action", [["class", "table__column-action"]], [[2, "element__indicator-wrapper", null], [2, "hidden", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_TableItemActionComponent_0, i8.RenderType_TableItemActionComponent)), i0.ɵdid(1, 770048, null, 0, i9.TableItemActionComponent, [i10.CustomizeService, i11.ActionService, i6.ActionControllerService, i12.NotificationService, i0.Injector, i0.ChangeDetectorRef], { row: [0, "row"], item: [1, "item"], context: [2, "context"], contextElement: [3, "contextElement"], accentColor: [4, "accentColor"], theme: [5, "theme"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.row; var currVal_3 = _v.context.$implicit; var currVal_4 = _co.context; var currVal_5 = _co.contextElement; var currVal_6 = _co.accentColor; var currVal_7 = _co.theme; _ck(_v, 1, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).indicatorWrapper; var currVal_1 = i0.ɵnov(_v, 1).hidden; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_TableItemComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "td", [["class", "table__column"]], [[2, "table__column_compact", null], [2, "table__column_clickable", null], [2, "table__column_no-trunc", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "table__column-actions"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableItemComponent_6)), i0.ɵdid(3, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.settings.rowActions; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = true; var currVal_2 = true; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_TableItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { columnElements: 1 }), (_l()(), i0.ɵeld(1, 0, null, null, 2, "td", [["class", "table__column table__column_left-padding"]], [[2, "table__column_clickable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleChecked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableItemComponent_1)), i0.ɵdid(3, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableItemComponent_2)), i0.ɵdid(5, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableItemComponent_5)), i0.ɵdid(7, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.checkEnabled; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.columnListItems; var currVal_3 = _co.trackByFn; _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = _co.settings.rowActions.length; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checkEnabled; _ck(_v, 1, 0, currVal_0); }); }
export function View_TableItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "app-table-item", [], null, null, null, View_TableItemComponent_0, RenderType_TableItemComponent)), i0.ɵdid(1, 770048, null, 0, i13.TableItemComponent, [i0.ElementRef, i0.ViewContainerRef, i14.ActivatedRoute, i0.ChangeDetectorRef, i11.ActionService, i15.ResourceControllerService, i16.LocalStorage], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TableItemComponentNgFactory = i0.ɵccf("app-table-item, [app-table-item]", i13.TableItemComponent, View_TableItemComponent_Host_0, { row: "row", resource: "resource", modelDescription: "modelDescription", visibleColumns: "visibleColumns", selected: "selected", checked: "checked", checkEnabled: "checkEnabled", settings: "settings", context: "context", contextElement: "contextElement", viewId: "viewId", accentColor: "accentColor", theme: "theme" }, { init: "init", destroy: "destroy", modelUpdated: "modelUpdated", selectToggle: "selectToggle", checkedToggle: "checkedToggle", changeColumnValue: "changeColumnValue" }, []);
export { TableItemComponentNgFactory as TableItemComponentNgFactory };

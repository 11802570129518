import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AutofocusDirective } from './components/autofocus/autofocus.directive';
import { BarProgressComponent } from './components/bar-progress/bar-progress.component';
import { CircleProgressComponent } from './components/circle-progress/circle-progress.component';
import { CustomCodeComponent } from './components/custom-code/custom-code.component';
import { ErrorComponent } from './components/error/error.component';
import { InlineSelectComponent } from './components/inline-select/inline-select.component';
import { JsonViewerComponent } from './components/json-viewer/json-viewer.component';
import { LicenseNotValidComponent } from './components/license-not-valid/license-not-valid.component';
import { LoaderSmallComponent } from './components/loader-small/loader-small.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PopupCollapseComponent } from './components/popup-collapse/popup-collapse.component';
import { RenderComponent } from './components/render/render.component';
import { ToastComponent } from './components/toast/toast.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { AttrDirective } from './directives/attr/attr.directive';
import { DateValueAccessorDirective } from './directives/date-value-accessor/date-value-accessor.directive';
import { ElementResizeDirective } from './directives/element-resize/element-resize.directive';
import { ElementDirective } from './directives/element/element.directive';
import { FileValueAccessorDirective } from './directives/file-value-accessor/file-value-accessor.directive';
import { FocusMonitorDirective } from './directives/focus-monitor/focus-monitor.directive';
import { FormControlSelectorDirective } from './directives/form-control-selector/form-control-selector.directive';
import { InputFocusableDirective } from './directives/input-focusable/input-focusable.directive';
import { JsonValueAccessorDirective } from './directives/json-value-accessor/json-value-accessor.directive';
import { KeyEventDirective } from './directives/key-event/key-event.directive';
import { LongHoverDirective } from './directives/long-hover/long-hover.directive';
import { MatMenuTriggerHoverForDirective } from './directives/mat-menu-trigger-hover-for/mat-menu-trigger-hover-for.directive';
import { AbsPipe } from './pipes/abs/abs.pipe';
import { AuditTimePipe } from './pipes/audit-time/audit-time.pipe';
import { CapitalizePipe } from './pipes/capitalize/capitalize.pipe';
import { CeilPipe } from './pipes/ceil/ceil.pipe';
import { ControlValuePipe } from './pipes/control-value/control-value.pipe';
import { DebounceTimePipe } from './pipes/debounce-time/debounce-time.pipe';
import { DecodeUriPipe } from './pipes/decode-uri/decode-uri.pipe';
import { DeleteHtmlPipe } from './pipes/delete-html/delete-html.pipe';
import { DeployCssUrlPipe } from './pipes/deploy-css-url/deploy-css-url.pipe';
import { DeployUrlPipe } from './pipes/deploy-url/deploy-url.pipe';
import { EmailNamePipe } from './pipes/email-name/email-name.pipe';
import { EncodeURIComponentPipe } from './pipes/encode-uri-component/encode-uri-component.pipe';
import { EncodeURIPipe } from './pipes/encode-uri/encode-uri.pipe';
import { FileNameFromPathPipe } from './pipes/file-name-from-path/file-name-from-path.pipe';
import { FirstSetPipe } from './pipes/first-set/first-set.pipe';
import { FloorPipe } from './pipes/floor/floor.pipe';
import { ForceObservablePipe } from './pipes/force-observable/force-observable.pipe';
import { FormatDate$Pipe } from './pipes/format-date$/format-date$.pipe';
import { FormatDatePipe } from './pipes/format-date/format-date.pipe';
import { FormatNumberPipe } from './pipes/format-number/format-number.pipe';
import { FormatTextPipe } from './pipes/format-text/format-text.pipe';
import { IndentLinesPipe } from './pipes/indent-lines/indent-lines.pipe';
import { IndentPipe } from './pipes/indent/indent.pipe';
import { InitialsPipe } from './pipes/initials/initials.pipe';
import { InputFocusedPipe } from './pipes/input-focused/input-focused.pipe';
import { IsAbsoluteUrlPipe } from './pipes/is-absolute-url/is-absolute-url.pipe';
import { IsColorHexPipe } from './pipes/is-color-hex/is-color-hex.pipe';
import { IsSetPipe } from './pipes/is-set/is-set.pipe';
import { RangePipe } from './pipes/range/range.pipe';
import { RatioPipe } from './pipes/ratio/ratio.pipe';
import { SafeAsyncPipe } from './pipes/safe-async/safe-async.pipe';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { SafeStylePipe } from './pipes/safe-style/safe-style.pipe';
import { SafeUrlPipe } from './pipes/safe-url/safe-url.pipe';
import { StripTagsPipe } from './pipes/strip-tags/strip-tags.pipe';
import { StubPipe } from './pipes/stub/stub.pipe';
import { ThrottleTimePipe } from './pipes/throttle-time/throttle-time.pipe';
import { TimeAgoPipe } from './pipes/time-ago/time-ago.pipe';
import { ToPairsPipe } from './pipes/to-pairs/to-pairs.pipe';

@NgModule({
  imports: [CommonModule, RouterModule, OverlayModule],
  declarations: [
    AutofocusDirective,
    NotFoundComponent,
    CapitalizePipe,
    DateValueAccessorDirective,
    JsonValueAccessorDirective,
    FileValueAccessorDirective,
    FormatNumberPipe,
    LoaderComponent,
    ErrorComponent,
    InputFocusedPipe,
    InputFocusableDirective,
    ElementDirective,
    SafeAsyncPipe,
    StubPipe,
    CircleProgressComponent,
    BarProgressComponent,
    FormatTextPipe,
    FormatDatePipe,
    FormatDate$Pipe,
    LoaderSmallComponent,
    DecodeUriPipe,
    ToPairsPipe,
    EncodeURIComponentPipe,
    RenderComponent,
    ToggleComponent,
    SafeStylePipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    EncodeURIPipe,
    DeleteHtmlPipe,
    EmailNamePipe,
    TimeAgoPipe,
    KeyEventDirective,
    FileNameFromPathPipe,
    PopupCollapseComponent,
    MatMenuTriggerHoverForDirective,
    IsSetPipe,
    LicenseNotValidComponent,
    StripTagsPipe,
    ControlValuePipe,
    DebounceTimePipe,
    AuditTimePipe,
    ThrottleTimePipe,
    RatioPipe,
    ElementResizeDirective,
    FormControlSelectorDirective,
    IndentPipe,
    IndentLinesPipe,
    InitialsPipe,
    InlineSelectComponent,
    RangePipe,
    JsonViewerComponent,
    IsAbsoluteUrlPipe,
    FocusMonitorDirective,
    LongHoverDirective,
    IsColorHexPipe,
    CustomCodeComponent,
    ToastComponent,
    AttrDirective,
    DeployUrlPipe,
    DeployCssUrlPipe,
    ForceObservablePipe,
    CeilPipe,
    FloorPipe,
    AbsPipe,
    FirstSetPipe
  ],
  exports: [
    AutofocusDirective,
    NotFoundComponent,
    CapitalizePipe,
    DateValueAccessorDirective,
    JsonValueAccessorDirective,
    FileValueAccessorDirective,
    FormatNumberPipe,
    LoaderComponent,
    ErrorComponent,
    InputFocusedPipe,
    InputFocusableDirective,
    ElementDirective,
    SafeAsyncPipe,
    StubPipe,
    CircleProgressComponent,
    BarProgressComponent,
    FormatTextPipe,
    FormatDatePipe,
    FormatDate$Pipe,
    LoaderSmallComponent,
    DecodeUriPipe,
    ToPairsPipe,
    EncodeURIComponentPipe,
    RenderComponent,
    ToggleComponent,
    SafeStylePipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    EncodeURIPipe,
    DeleteHtmlPipe,
    EmailNamePipe,
    TimeAgoPipe,
    KeyEventDirective,
    FileNameFromPathPipe,
    PopupCollapseComponent,
    MatMenuTriggerHoverForDirective,
    IsSetPipe,
    StripTagsPipe,
    ControlValuePipe,
    DebounceTimePipe,
    AuditTimePipe,
    ThrottleTimePipe,
    RatioPipe,
    ElementResizeDirective,
    FormControlSelectorDirective,
    IndentPipe,
    IndentLinesPipe,
    InitialsPipe,
    InlineSelectComponent,
    RangePipe,
    JsonViewerComponent,
    IsAbsoluteUrlPipe,
    FocusMonitorDirective,
    LongHoverDirective,
    IsColorHexPipe,
    CustomCodeComponent,
    ToastComponent,
    AttrDirective,
    DeployUrlPipe,
    DeployCssUrlPipe,
    ForceObservablePipe,
    CeilPipe,
    FloorPipe,
    AbsPipe,
    FirstSetPipe
  ]
})
export class SharedModule {}

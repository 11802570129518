<app-popup2
  [size]="'s'"
  [side]="sharedCustomSSOWithSide.includes(form.controls.custom.controls.shared_custom_sso.value)"
>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <app-popup2-side>
      <div class="custom-page-popup custom-page-popup_style_background custom-page-popup_border-radius_s">
        <div class="custom-page-popup__header" [class.custom-page-popup__header_overlay]="false">
          <div class="custom-page-popup__header-main">
            <div class="custom-page-popup__title">
              <ng-container *ngIf="!sso">Adding SSO application</ng-container>
              <ng-container *ngIf="sso">Editing {{ sso.name }}</ng-container>
            </div>
          </div>
        </div>
        <div class="custom-page-popup__content">
          <app-page-block [fill]="true">
            <app-page-block [marginSize]="'s'">
              <app-auto-field
                [form]="form"
                [field]="
                  createField({
                    name: 'name',
                    label: 'Name',
                    field: 'CharField',
                    required: true,
                    description: 'This name will be displayed on Sign in button',
                    placeholder: 'SSO',
                    params: { classes: ['input_fill'] }
                  })
                "
              ></app-auto-field>
            </app-page-block>

            <app-page-block>
              <div class="field">
                <label class="field__label">
                  <span class="field__label-text">image</span>
                  <span class="field__label-optional">(optional)</span>
                </label>
                <div class="field__value">
                  <app-auto-field
                    [form]="form"
                    [label]="false"
                    [field]="
                      createField({
                        name: 'image',
                        field: 'ImageField',
                        params: {
                          path: 'sso_settings/images/',
                          storage_resource: form.storageResource.uniqueName,
                          storage_name: form.storage.uniqueName,
                          ignore_old_django_format: true,
                          editor: false,
                          fit: imageFits.Fit,
                          background: true
                        }
                      })
                    "
                  ></app-auto-field>
                </div>
                <div class="field__description">
                  Recommended size is 32x32, .png
                </div>
              </div>
            </app-page-block>

            <app-page-block
              *ngIf="
                currentEnvironmentStore.instance.uniqueName ==
                currentProjectStore.instance.defaultEnvironment?.uniqueName
              "
              [marginSize]="'s'"
            >
              <div class="field">
                <label class="field__label">New members team</label>
                <div class="field__value">
                  <app-project-group-control
                    [control]="form.controls.default_group"
                    [uidValue]="true"
                    [fill]="true"
                    [allowNull]="true"
                    [environmentName]="currentProjectStore.instance.defaultEnvironment.uniqueName"
                  ></app-project-group-control>
                </div>
                <div class="field__description">
                  This team will be assigned to all newly registered members
                </div>
              </div>
            </app-page-block>
          </app-page-block>

          <ng-container
            *ngIf="
              !(form.controls.type.value == ssoTypes.Custom && form.controls.custom.controls.shared_custom_sso.value)
            "
          >
            <app-page-block>
              <hr />
            </app-page-block>

            <app-page-block>
              <app-page-heading>Configuration</app-page-heading>
            </app-page-block>

            <app-page-block [fill]="true">
              <app-page-block [marginSize]="'s'">
                <app-auto-field
                  [form]="form"
                  [field]="
                    createField({
                      name: 'type',
                      label: 'SSO type',
                      field: 'SelectField',
                      required: true,
                      params: { classes: ['select_fill'], options: form.typeOptions }
                    })
                  "
                ></app-auto-field>
              </app-page-block>
            </app-page-block>
          </ng-container>

          <ng-container *ngIf="form.controls.type.value == ssoTypes.SAML2">
            <app-page-block [fill]="true">
              <app-page-block [marginSize]="'s'">
                <app-auto-field
                  [form]="form.controls.saml2"
                  [field]="
                    createField({
                      name: 'entity_id',
                      label: 'Entity ID',
                      field: 'CharField',
                      required: true,
                      params: { classes: ['input_fill'] }
                    })
                  "
                ></app-auto-field>
              </app-page-block>

              <app-page-block [marginSize]="'s'">
                <div class="field">
                  <label class="field__label" for="metadata">Metadata (.xml)</label>

                  <div
                    class="upload upload_background"
                    [class.upload_empty]="!form.controls.saml2.controls.metadata.value"
                    [class.upload_uploadable]="true"
                    [class.upload_active]="metadataDraggingOver"
                    style="height: 120px;"
                    (dragover)="onMetadataDragOver($event)"
                    (dragleave)="onMetadataDragLeave($event)"
                    (drop)="onMetadataDrop($event)"
                  >
                    <div *ngIf="form.controls.saml2.controls.metadata.value" class="upload__preview">
                      <div class="upload__background upload__background_padding">
                        <app-file-preview
                          [previewFilename]="metadataFilename"
                          [previewExtension]="metadataExtension"
                        ></app-file-preview>
                      </div>
                    </div>

                    <ng-container *ngIf="!form.controls.saml2.controls.metadata.value">
                      <div class="upload__placeholder">
                        <div class="upload__placeholder-icon icon-cloud_upload"></div>
                        <div class="upload__placeholder-label">
                          Drop file here or
                          <label [for]="'metadata'" class="upload__placeholder-button">Choose file</label>
                        </div>
                      </div>
                    </ng-container>

                    <div class="upload__controls">
                      <input
                        class="input input_file"
                        type="file"
                        accept=".xml"
                        (change)="onMetadataFileChange($event.target)"
                        id="metadata"
                      />

                      <ng-container *ngIf="form.controls.saml2.controls.metadata.value">
                        <div class="upload__buttons">
                          <a
                            href="javascript:void(0)"
                            class="upload__button icon-bin"
                            (click)="form.controls.saml2.controls.metadata.setValue(null)"
                            [appTip]="'Clear'"
                            [appTipOptions]="{ side: 'top', margin: -4 }"
                          ></a>

                          <label
                            [for]="'metadata'"
                            class="upload__button icon-cloud_upload"
                            [appTip]="'Upload new'"
                            [appTipOptions]="{ side: 'top', margin: -4 }"
                          >
                          </label>

                          <ng-content select="ng-container[upload-actions]"></ng-content>
                        </div>
                      </ng-container>
                    </div>

                    <label
                      *ngIf="!form.controls.saml2.controls.metadata.value"
                      class="upload__overlay"
                      [for]="'metadata'"
                    >
                    </label>
                  </div>

                  <app-field-errors [form]="form.controls.saml2" [fieldName]="'metadata'"></app-field-errors>
                </div>
              </app-page-block>
            </app-page-block>

            <app-page-block>
              <hr />
            </app-page-block>

            <app-page-block>
              <app-page-heading>Application parameters</app-page-heading>
            </app-page-block>

            <app-page-block>
              <div *ngIf="sso" class="field">
                <label class="field__label">
                  <span class="field__label-text">ACS URL</span>
                  <a
                    href="javascript:void(0)"
                    class="field__label-link"
                    style="margin-left: 4px;"
                    (click)="$event.stopPropagation(); copy(samlRedirectUrl, 'SAML ACS URL')"
                  >
                    <span class="field__label-link-icon icon-duplicate"></span>
                    Copy
                  </a>
                </label>
                <div class="field__value">
                  <div class="input-icon-wrapper">
                    <span class="input-icon input-icon_left icon-link"></span>
                    <input
                      class="input input_icon-left input_fill"
                      [value]="samlRedirectUrl"
                      (click)="$event.target.select()"
                      readonly
                    />
                  </div>
                </div>
              </div>

              <div *ngIf="!sso" class="field">
                <label class="field__label">
                  ACS URL
                </label>
                <div class="field__value">
                  <div class="input-icon-wrapper">
                    <span class="input-icon input-icon_left icon-link"></span>
                    <input
                      class="input input_disabled input_icon-left input_fill"
                      value="create SSO first"
                      (click)="$event.target.select()"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </app-page-block>
          </ng-container>

          <ng-container *ngIf="form.controls.type.value == ssoTypes.OAuth2">
            <app-page-block [fill]="true">
              <app-oauth2-params
                [control]="form.controls.oauth2.controls.oauth_params"
                [userDetailsWorkflowControl]="form.controls.oauth2.controls.user_details_workflow"
                [createOauthTokenRedirectUrl]="false"
                [fill]="true"
              ></app-oauth2-params>
            </app-page-block>

            <app-page-block
              *ngIf="form.controls.oauth2.controls.oauth_params.value['backend'] == customOAuth2BackendName"
            >
              <app-alert [title]="'Custom integration'" [info]="true">
                If you have some specific OAuth configuration please contact us to adapt our OAuth integration for your
                implementation.

                <ng-container actions>
                  <button type="button" class="button button_small" style="margin: 0;" (click)="openChat()">
                    <span class="button__icon button__icon_left icon-chat"></span>
                    <span class="button__label">Contact us</span>
                  </button>
                </ng-container>
              </app-alert>
            </app-page-block>

            <ng-container *ngIf="form.controls.oauth2.controls.oauth_params.value['backend']">
              <app-page-block>
                <hr />
              </app-page-block>

              <app-page-block>
                <app-page-heading>Application parameters</app-page-heading>
              </app-page-block>

              <app-page-block>
                <div class="field">
                  <label class="field__label">
                    <span class="field__label-text">Redirect URL</span>
                    <a
                      href="javascript:void(0)"
                      class="field__label-link"
                      style="margin-left: 4px;"
                      (click)="$event.stopPropagation(); copy(oauthRedirectUrl, 'OAuth Redirect URL')"
                    >
                      <span class="field__label-link-icon icon-duplicate"></span>
                      Copy
                    </a>
                  </label>
                  <div class="field__value">
                    <div class="input-icon-wrapper">
                      <span class="input-icon input-icon_left icon-link"></span>
                      <input
                        class="input input_icon-left input_fill"
                        [value]="oauthRedirectUrl"
                        (click)="$event.target.select()"
                        readonly
                      />
                    </div>
                  </div>
                </div>
              </app-page-block>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="form.controls.type.value == ssoTypes.Custom">
            <ng-container [ngSwitch]="form.controls.custom.controls.shared_custom_sso.value">
              <ng-container *ngSwitchCase="sharedCustomSSOTypes.Firebase">
                <app-page-block>
                  <app-auto-field
                    [form]="parametersGroup"
                    [field]="
                      createField({
                        name: 'Web API Key',
                        label: 'Web API Key',
                        field: 'CharField',
                        params: { classes: ['input_fill'] },
                        required: true,
                        placeholder: 'AIzaSyDAhSEPR9ha6C3.................',
                        description: web_api_key_description
                      })
                    "
                  ></app-auto-field>

                  <ng-template #web_api_key_description>
                    You can obtain it here:<br />
                    <strong>Firebase > Project Settings > General > Web API Key</strong>
                  </ng-template>
                </app-page-block>
              </ng-container>

              <ng-container *ngSwitchCase="sharedCustomSSOTypes.Supabase">
                <app-page-block>
                  <app-auto-field
                    [form]="parametersGroup"
                    [field]="
                      createField({
                        name: 'Reference ID',
                        label: 'Project Reference ID',
                        field: 'CharField',
                        params: { classes: ['input_fill'] },
                        required: true,
                        placeholder: 'znbagmgodm........',
                        description: reference_id_description
                      })
                    "
                  ></app-auto-field>

                  <ng-template #reference_id_description>
                    You can obtain it here:<br />
                    <strong>Supabase > Project Settings > General > Reference ID</strong>
                  </ng-template>
                </app-page-block>

                <app-page-block>
                  <app-auto-field
                    [form]="parametersGroup"
                    [field]="
                      createField({
                        name: 'API key',
                        label: 'API key',
                        field: 'CharField',
                        params: { classes: ['input_fill'] },
                        required: true,
                        placeholder: 'eyJhbGguOiJIUzI1HyIsIn5yt5cCI6IkpXVCJ9.eyJp.........',
                        description: api_key_description
                      })
                    "
                  ></app-auto-field>

                  <ng-template #api_key_description>
                    <strong>Supabase > Project Settings > API > Project API keys > anon</strong>
                  </ng-template>
                </app-page-block>
              </ng-container>

              <app-parameter-values
                *ngSwitchDefault
                [control]="form.controls.custom.controls.parameters"
                [customizable]="!form.controls.custom.controls.shared_custom_sso.value"
              ></app-parameter-values>
            </ng-container>

            <app-page-block *ngIf="!form.controls.custom.controls.shared_custom_sso.value">
              <div class="field">
                <label class="field__label">
                  Workflows
                </label>
                <div class="field__value">
                  <app-page-block [marginSize]="'s'" [paddingSize]="'none'">
                    <app-workflow-button
                      [workflow]="authWorkflow"
                      [title]="'Login workflow'"
                      [icon]="'signin'"
                      (editClick)="customizeAuthWorkflow()"
                    ></app-workflow-button>
                  </app-page-block>

                  <app-page-block [marginSize]="'s'" [paddingSize]="'none'">
                    <app-workflow-button
                      [workflow]="refreshTokenWorkflow"
                      [title]="'Refresh token workflow'"
                      [icon]="'repeat'"
                      [emptySubtitle]="'Not specified (Optional)'"
                      [emptyDisabled]="true"
                      [remove]="true"
                      (editClick)="customizeRefreshTokenWorkflow()"
                      (removeClick)="deleteRefreshTokenWorkflow()"
                    ></app-workflow-button>
                  </app-page-block>
                </div>
              </div>
            </app-page-block>
          </ng-container>

          <ng-container *ngIf="form.controls.type.value == ssoTypes.JetBridge">
            <app-page-block>
              <app-auto-field
                [form]="form.controls.jet_bridge"
                [field]="
                  createField({
                    name: 'url',
                    label: 'Jet Bridge URL',
                    field: 'CharField',
                    required: true,
                    params: { classes: ['input_fill'] }
                  })
                "
              ></app-auto-field>
            </app-page-block>

            <app-page-block>
              <hr />
            </app-page-block>

            <app-page-block>
              <app-page-heading>Application parameters</app-page-heading>
            </app-page-block>

            <app-page-block [fill]="true">
              <app-page-block [marginSize]="'s'">
                <div class="field">
                  <label class="field__label">SSO application ID</label>
                  <div class="field__value">
                    <input
                      *ngIf="sso"
                      class="input input_icon-left input_fill"
                      [value]="sso.uid"
                      (click)="$event.target.select()"
                      readonly
                    />

                    <input
                      *ngIf="!sso"
                      class="input input_disabled input_icon-left input_fill"
                      value="create SSO first"
                      (click)="$event.target.select()"
                      readonly
                    />
                  </div>
                </div>
              </app-page-block>

              <app-page-block [marginSize]="'s'">
                <div *ngIf="sso" class="field">
                  <label class="field__label">
                    <span class="field__label-text">OAuth Callback URL</span>
                    <a
                      href="javascript:void(0)"
                      class="field__label-link"
                      style="margin-left: 4px;"
                      (click)="$event.stopPropagation(); copy(jetBridgeRedirectUrl, 'OAuth Callback URL')"
                    >
                      <span class="field__label-link-icon icon-duplicate"></span>
                      Copy
                    </a>
                  </label>
                  <div class="field__value">
                    <div class="input-icon-wrapper">
                      <span class="input-icon input-icon_left icon-link"></span>
                      <input
                        class="input input_icon-left input_fill"
                        [value]="jetBridgeRedirectUrl"
                        (click)="$event.target.select()"
                        readonly
                      />
                    </div>
                  </div>
                </div>

                <div *ngIf="!sso" class="field">
                  <label class="field__label">
                    OAuth Callback URL
                  </label>
                  <div class="field__value">
                    <div class="input-icon-wrapper">
                      <span class="input-icon input-icon_left icon-link"></span>
                      <input
                        class="input input_disabled input_icon-left input_fill"
                        value="create SSO first"
                        (click)="$event.target.select()"
                        readonly
                      />
                    </div>
                  </div>
                </div>
              </app-page-block>
            </app-page-block>
          </ng-container>

          <app-page-block>
            <app-stack [align]="'right'">
              <ng-container *ngIf="sso">
                <a
                  href="javascript:void(0)"
                  class="button button_orange-alternative popup2__footer-item"
                  [class.button_disabled]="deleteLoading"
                  (click)="deleteSSOSettings()"
                >
                  <span *ngIf="!deleteLoading" class="button__icon button__icon_left icon-bin"></span>
                  <app-loader-small *ngIf="deleteLoading" class="button__icon button__icon_left"></app-loader-small>
                  <span class="button__label">Delete SSO</span>
                </a>
                <span class="popup2__footer-spacing"></span>
              </ng-container>

              <button
                type="button"
                class="button button_orange-transparent popup2__footer-item"
                (click)="cancel()"
                [disabled]="deleteLoading || submitLoading"
              >
                Cancel
              </button>

              <button
                type="submit"
                class="button button_primary button_shadow button_orange popup2__footer-item"
                [disabled]="deleteLoading || submitLoading"
              >
                <app-loader-small *ngIf="submitLoading" class="button__icon button__icon_left"></app-loader-small>
                <ng-container *ngIf="!sso">Create</ng-container>
                <ng-container *ngIf="sso">Save</ng-container>
              </button>
            </app-stack>
          </app-page-block>
        </div>
      </div>

      <ng-container data-side>
        <ng-container *ngIf="form.controls.custom.controls.shared_custom_sso.value == sharedCustomSSOTypes.Firebase">
          <p class="resource-popup__banner-description">
            The API Key allows us to integrate Firebase Auth. Firebase > Project Settings > General > Web API Key
          </p>
          <video
            class="resource-popup__banner-video resource-popup__banner-video_zoom"
            [src]="'/assets/video/firebase-auth.mp4' | appDeployUrl"
            autoplay
            loop
            muted
            [appLightbox]="'/assets/video/firebase-auth.mp4' | appDeployUrl"
            [appLightboxVideo]="true"
            [appLightboxOrigin]="self"
            #self
          ></video>
        </ng-container>

        <ng-container *ngIf="form.controls.custom.controls.shared_custom_sso.value == sharedCustomSSOTypes.Supabase">
          <p class="resource-popup__banner-description">
            The Reference ID and API key allows us to integrate Supabase Auth. Supabase > Project Settings
          </p>
          <video
            class="resource-popup__banner-video resource-popup__banner-video_zoom"
            [src]="'/assets/video/supabase-auth.mp4' | appDeployUrl"
            autoplay
            loop
            muted
            [appLightbox]="'/assets/video/supabase-auth.mp4' | appDeployUrl"
            [appLightboxVideo]="true"
            [appLightboxOrigin]="self"
            #self
          ></video>
        </ng-container>
      </ng-container>
    </app-popup2-side>
  </form>
</app-popup2>
